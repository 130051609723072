import React, { useMemo } from 'react'
import { Layout, Row, Col, Typography } from 'antd'
import centricLogoFull from 'components/Images/icons/centric_logo_white.svg'
import { telegram, discord } from 'components/Images/footer/FooterImages'
import { CMC, COINGEKO, WHITEPAPER, CENTRIC_WEBSITE } from 'constants/links'
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  RedditSquareFilled,
  TwitterSquareFilled,
  YoutubeFilled,
} from '@ant-design/icons'
import {
  Container,
  AppFooterText,
  ColHeader,
  ColList,
  ColListSocial,
  ColListItem,
  ColListSocialItem,
  SocialText,
  SignoffList,
  SignoffListEnd,
  SignoffListItem,
  CentricLogo,
  AppFooterContracts,
} from './styled-components'

const { Footer } = Layout
const { Text } = Typography

const learn = [
  {
    text: 'Basics',
    link: `${CENTRIC_WEBSITE}/basics/`,
  },
  {
    text: 'Roadmap',
    link: `${CENTRIC_WEBSITE}/centric-roadmap/`,
  },
  {
    text: 'Whitepaper',
    link: WHITEPAPER,
  },
  {
    text: 'Blog',
    link: `${CENTRIC_WEBSITE}/blog/`,
  },
  {
    text: 'Guide',
    link: `${CENTRIC_WEBSITE}/guide/`,
  },
  {
    text: 'FAQs',
    link: `${CENTRIC_WEBSITE}/faq-general/`,
  },
  {
    text: 'Foundation',
    link: `${CENTRIC_WEBSITE}/about/`,
  },
]

const resources = [
  {
    text: 'Centric Swap',
    link: 'https://www.centricswap.com/',
  },
  {
    text: 'Centric Wallet',
    link: 'https://wallet.centric.com/',
  },
  {
    text: 'Support',
    link: `${CENTRIC_WEBSITE}/support/`,
  },
  {
    text: 'CoinMarketCap',
    link: CMC,
  },
  {
    text: 'CoinGeko',
    link: COINGEKO,
  },
  {
    text: 'CNR BscScan',
    link: 'https://bscscan.com/token/0xdcba3fbd7bbc28abd18a472d28358089467a8a74',
  },
  {
    text: 'CNS BscScan',
    link: 'https://bscscan.com/token/0xf6cb4ad242bab681effc5de40f7c8ff921a12d63',
  },
  {
    text: 'CNS PancakeSwap',
    link: 'https://pancakeswap.finance/info/token/0xf6cb4ad242bab681effc5de40f7c8ff921a12d63',
  },
]

const AppFooter: React.FC = () => {
  const socials = useMemo(() => {
    return [
      {
        text: 'Facebook',
        link: 'https://www.facebook.com/centricfoundation',
        icon: <FacebookFilled />,
      },
      {
        text: 'Twitter',
        link: 'https://twitter.com/centricrise',
        icon: <TwitterSquareFilled />,
      },
      {
        text: 'Youtube',
        link: 'https://www.youtube.com/channel/UC78BowQEg4xHJBTYHL6YTWg',
        icon: <YoutubeFilled />,
      },
      {
        text: 'Telegram',
        link: 'https://t.me/CentricOfficial',
        icon: <img src={telegram} style={{ width: 16 }} alt="" />,
      },
      {
        text: 'Telegram Chat',
        link: 'https://t.me/centricchat',
        icon: <img src={telegram} style={{ width: 16 }} alt="" />,
      },
      {
        text: 'Instagram',
        link: 'https://www.instagram.com/centric_foundation',
        icon: <InstagramFilled />,
      },
      {
        text: 'Discord',
        link: 'https://discord.gg/KjwY64h',
        icon: <img src={discord} style={{ width: 16 }} alt="" />,
      },
      {
        text: 'Reddit',
        link: 'https://www.reddit.com/user/joincentric',
        icon: <RedditSquareFilled />,
      },
      {
        text: 'LinkedIn',
        link: 'https://www.linkedin.com/company/centricfoundation',
        icon: <LinkedinFilled />,
      },
    ]
  }, [])

  return (
    <Footer
      style={{
        backgroundColor: '#23292E',
        marginTop: '4rem',
        padding: '3rem 1rem 1rem',
      }}
    >
      <Container>
        <Row gutter={64}>
          <Col lg={11} md={7} sm={24} xs={24}>
            <CentricLogo src={centricLogoFull} alt="" />
            <AppFooterText>
              Centric is an innovative dual-token digital currency and decentralized blockchain protocol built on sound
              economics. The dual-token model rewards adoption and stabilizes over time as it self-regulates token
              supply to meet ongoing changes in demand.
            </AppFooterText>
            <AppFooterContracts>
              <Text>Centric Rise (CNR) Contract</Text>
              <br />
              <Text copyable>0xdCbA3fbd7BBc28abD18A472D28358089467A8a74</Text>
            </AppFooterContracts>
            <AppFooterContracts>
              <Text>Centric Swap (CNS) Contract</Text>
              <br />
              <Text copyable>0xF6Cb4ad242BaB681EfFc5dE40f7c8FF921a12d63</Text>
            </AppFooterContracts>
          </Col>
          <Col lg={{ span: 4, offset: 1 }} md={{ span: 5, offset: 1 }} sm={8} xs={12}>
            <ColHeader>Learn</ColHeader>
            <ColList>
              {learn.map((item) => (
                <ColListItem key={item.text}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.text}
                  </a>
                </ColListItem>
              ))}
            </ColList>
          </Col>
          <Col lg={4} md={5} sm={8} xs={12}>
            <ColHeader>Resources</ColHeader>
            <ColList>
              {resources.map((item) => (
                <ColListItem key={item.text}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.text}
                  </a>
                </ColListItem>
              ))}
            </ColList>
          </Col>
          <Col lg={4} md={5} sm={8} xs={24}>
            <ColHeader>Socials</ColHeader>
            <ColListSocial>
              {socials.map((item) => (
                <ColListSocialItem key={item.text}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.icon}
                    <SocialText>{item.text}</SocialText>
                  </a>
                </ColListSocialItem>
              ))}
            </ColListSocial>
          </Col>
        </Row>
        <Row
          style={{ marginTop: 30, paddingTop: 12, borderTop: '1px solid rgba(255, 255, 255, 0.65)' }}
          justify="space-between"
        >
          <Col flex="auto">
            <SignoffList>
              <SignoffListItem>© 2019-2024 The Centric Corp Ltd. All rights reserved.</SignoffListItem>
            </SignoffList>
          </Col>
          <Col flex="auto">
            <SignoffListEnd>
              <SignoffListItem>
                <a href="https://www.centric.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </SignoffListItem>
              <SignoffListItem>
                <a href="https://www.centric.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
                  Terms of service
                </a>
              </SignoffListItem>
            </SignoffListEnd>
          </Col>
        </Row>
      </Container>
    </Footer>
  )
}

export default AppFooter
