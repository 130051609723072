import React from 'react'
import { NavLink } from 'react-router-dom'
import { Menu } from 'antd'
import { BlockOutlined, GlobalOutlined, TagsOutlined, ArrowRightOutlined, HomeOutlined } from '@ant-design/icons'
import { BSC_SCAN } from 'constants/config'
import { CNR } from 'constants/tokens'

const { SubMenu } = Menu

interface Props {
  type: string
}

const NavMenu: React.FC<Props> = ({ type }) => {
  return (
    <Menu
      theme="dark"
      mode={type === 'desktop' ? 'horizontal' : 'inline'}
      style={{ minWidth: '100%', backgroundColor: '#000' }}
    >
      <Menu.Item icon={type === 'desktop' ? false : <HomeOutlined />} key="home">
        <NavLink to="/">Home</NavLink>
      </Menu.Item>
      <SubMenu icon={<BlockOutlined />} title="Blockchain" key="blockchain">
        <Menu.Item key="blockchain:1">
          <NavLink to="/blockchain/transactions">Transactions</NavLink>
        </Menu.Item>
        <Menu.Item key="blockchain:2">
          <NavLink to="/blockchain/conversions">Conversions</NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu icon={<TagsOutlined />} title="Protocol" key="protocol">
        <Menu.Item key="tokens:1">
          <NavLink to="/tokens/rise">Centric Rise (CNR)</NavLink>
        </Menu.Item>
        <Menu.Item key="tokens:2">
          <NavLink to="/tokens/swap">Centric Swap (CNS)</NavLink>
        </Menu.Item>
        <Menu.Item key="protocol:3">
          <NavLink to="/burn">Burn History</NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu icon={<GlobalOutlined />} title="Data" key="data">
        <Menu.Item key="data:1">
          <NavLink to="/data/prices">Price Blocks</NavLink>
        </Menu.Item>
        <Menu.Item key="data:2">
          <a href={`${BSC_SCAN}/token/${CNR.address}#balances`} target="_blank" rel="noopener noreferrer">
            Holders <ArrowRightOutlined />
          </a>
        </Menu.Item>
        <Menu.Item key="data:3">
          <NavLink to="/data/foundation">Foundation Addresses</NavLink>
        </Menu.Item>
      </SubMenu>
    </Menu>
  )
}

export default NavMenu
