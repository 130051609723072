import React, { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { DefaultWrapperRoute, AppHeader, AppFooter, Search } from 'components'
import NotFound from 'views/NotFound/NotFound'
import { Layout, Spin } from 'antd'
import { useCentricCallback } from 'state/centric/hooks'
import 'antd/dist/antd.css'
import 'styles/overrides.css'

const Homepage = lazy(() => import('views/Homepage/Homepage'))
const Transactions = lazy(() => import('views/Transactions/Transactions'))
const Conversions = lazy(() => import('views/Conversions/Conversions'))
const Burns = lazy(() => import('views/Burns/Burns'))
const PriceBlocks = lazy(() => import('views/PriceBlocks/PriceBlocks'))
const Foundation = lazy(() => import('views/Foundation/Foundation'))
const Account = lazy(() => import('views/Account/Account'))
const Transaction = lazy(() => import('views/Transaction/Transaction'))
const Cnr = lazy(() => import('views/Cnr/Cnr'))
const Cns = lazy(() => import('views/Cns/Cns'))
const RawData = lazy(() => import('views/RawData/RawData'))

const { Content } = Layout

const App: React.FC = () => {
  useCentricCallback()

  return (
    <Layout>
      <AppHeader />
      <Search />
      <Content
        style={{
          width: '100%',
          maxWidth: 1200,
          paddingRight: 15,
          paddingLeft: 15,
          marginRight: 'auto',
          marginLeft: 'auto',
          minHeight: 'calc(100vh - 136px)',
        }}
      >
        <Suspense fallback={<Spin />}>
          <Switch>
            <DefaultWrapperRoute path="/" exact component={Homepage} />
            <DefaultWrapperRoute path="/blockchain/transactions" exact component={Transactions} />
            <DefaultWrapperRoute path="/blockchain/conversions" exact component={Conversions} />
            <DefaultWrapperRoute path="/burn" exact component={Burns} />
            <DefaultWrapperRoute path="/data/prices" exact component={PriceBlocks} />
            <DefaultWrapperRoute path="/data/foundation" exact component={Foundation} />
            <DefaultWrapperRoute path="/tokens/rise" exact component={Cnr} />
            <DefaultWrapperRoute path="/tokens/swap" exact component={Cns} />
            <DefaultWrapperRoute path="/account/:address" exact component={Account} />
            <DefaultWrapperRoute path="/transaction/:txid" exact component={Transaction} />
            <DefaultWrapperRoute path="/process" exact component={RawData} />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </Content>
      <AppFooter />
    </Layout>
  )
}

export default App
