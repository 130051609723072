import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useSearchCallback from 'hooks/useSearchCallback'
import styled from 'styled-components'
import { Select, Button } from 'antd'

export const Backgound = styled.div`
  box-shadow: rgba(4, 4, 64, 0.05) 0px 2px 40px 0px;
  background: rgb(243, 243, 243);
  padding: 1rem;
  margin-bottom: 1.5rem;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`

const SearchBox: React.FC = () => {
  const history = useHistory()
  const [query, updateQuery] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const handleInput = (input) => {
    if (input && input.length > 2) {
      updateQuery(input)
    }
  }

  const searchCallback = useSearchCallback(query)

  const handleSubmit = useCallback(() => {
    setLoading(true)
    const redirectUrl = searchCallback()
    history.push(redirectUrl)
    setLoading(false)
    updateQuery(undefined)
  }, [searchCallback, history])

  return (
    <Backgound>
      <Container>
        <Select
          showSearch
          value={query}
          placeholder="Search by Address / Txn Hash"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(value) => handleInput(value)}
          notFoundContent={null}
          size="large"
          style={{ width: '100%' }}
          optionLabelProp="label"
        />
        <Button type="primary" size="large" loading={loading} onClick={() => handleSubmit()}>
          Search
        </Button>
      </Container>
    </Backgound>
  )
}

export default SearchBox
