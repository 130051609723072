export const CENTRIC_WEBSITE = 'https://www.centric.com'
export const CMC = 'https://coinmarketcap.com/currencies/centric-cash/'
export const COINGEKO = 'https://www.coingecko.com/en/coins/centric'
export const WHITEPAPER = 'https://www.centric.com/docs/Centric_Whitepaper.pdf'
export const SOCIALS = [
  {
    type: 'twitter',
    link: 'https://twitter.com/centricrise',
  },
  {
    type: 'facebook',
    link: 'https://www.facebook.com/centricfoundation',
  },
  {
    type: 'telegram',
    link: 'https://t.me/CentricChat',
  },
  {
    type: 'discord',
    link: 'https://discord.gg/KjwY64h',
  },
]
