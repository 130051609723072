import { useMemo } from 'react'
import { ethers } from 'ethers'
import { CNR, CNS } from 'constants/tokens'

export default function useSearchCallback(query: string): null | (() => string) {
  return useMemo(() => {
    if (!query) {
      return undefined
    }

    return function onSubmit(): string {
      if (query.toLowerCase() === CNR.address.toLowerCase()) {
        return '/tokens/rise'
      }

      if (query.toLowerCase() === CNS.address.toLowerCase()) {
        return '/tokens/swap'
      }

      if (query.length === 42) {
        try {
          ethers.utils.getAddress(query)
          return `/account/${query}`
        } catch (e) {
          return `/search/${query}`
        }
      }

      if (query.length === 66) {
        return `/transaction/${query}`
      }

      return `/search/${query}`
    }
  }, [query])
}
