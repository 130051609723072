export interface Token {
  address: string
  decimals: number
  symbol: string
  name: string
  shortName: string
  created: number
  initialSupply: number
  audits: { name: string; link: string }[]
}

export enum TOKEN {
  CNR = 'CNR',
  CNS = 'CNS',
}

export const CNS: Token = {
  address: '0xF6Cb4ad242BaB681EfFc5dE40f7c8FF921a12d63',
  decimals: 8,
  symbol: 'CNS',
  name: 'Centric Swap',
  shortName: 'Swap',
  created: 1622634900000,
  initialSupply: 0,
  audits: [
    {
      name: 'Iosiro',
      link: 'https://iosiro.com/audits/centric-cash-smart-contract-audit',
    },
  ],
}

export const CNR: Token = {
  address: '0xdCbA3fbd7BBc28abD18A472D28358089467A8a74',
  decimals: 8,
  symbol: 'CNR',
  name: 'Centric Rise',
  shortName: 'Rise',
  created: 1622634900000,
  initialSupply: 1000000000,
  audits: [
    {
      name: 'Iosiro',
      link: 'https://iosiro.com/audits/centric-protocol-smart-contract-audit',
    },
  ],
}
