import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { CentricItemStatus, CentricCNRPrice, CentricInfo, CentricCNSPrice, CentricState } from './types'

const noCentricConfig = {
  cnrPrice: {
    data: {
      current: undefined,
      next: undefined,
    },
    isInitialized: false,
    expiry: new Date().valueOf(),
    status: CentricItemStatus.READY,
  },
  info: {
    data: {
      cnrCirculating: undefined,
      cnrBurnt: undefined,
      cnrMax: undefined,
      cnrQuarantined: undefined,
      cnrActive: undefined,
      cnsCirculating: undefined,
      cnsBurnt: undefined,
    },
    isInitialized: false,
    expiry: new Date().valueOf(),
    status: CentricItemStatus.READY,
  },
  cnsPrice: {
    data: {
      current: undefined,
    },
    isInitialized: false,
    expiry: new Date().valueOf(),
    status: CentricItemStatus.READY,
  },
}

const initialState: CentricState = { ...noCentricConfig }

export const centricSlice = createSlice({
  name: 'centric',
  initialState,
  reducers: {
    updateCnrPrice: (state, action: PayloadAction<CentricCNRPrice>) => {
      if (action.payload.status === CentricItemStatus.FINISHED) {
        state.cnrPrice = {
          data: action.payload.data,
          isInitialized: true,
          status: action.payload.status,
          expiry:
            moment().minute() > 44
              ? moment().add(1, 'h').set('minute', 45).valueOf()
              : moment().set('minute', 45).valueOf(),
        }
      }
      if (action.payload.status === CentricItemStatus.LOADING) {
        state.cnrPrice = {
          ...state.cnrPrice,
          status: action.payload.status,
        }
      }
      if (action.payload.status === CentricItemStatus.ERROR) {
        state.cnrPrice = {
          ...state.cnrPrice,
          expiry: moment().add(9, 's').valueOf(),
          status: action.payload.status,
        }
      }
    },
    updateCnsPrice: (state, action: PayloadAction<CentricCNSPrice>) => {
      if (action.payload.status === CentricItemStatus.FINISHED) {
        state.cnsPrice = {
          data: action.payload.data,
          status: action.payload.status,
          isInitialized: true,
          expiry: moment().add(5, 'm').valueOf(),
        }
      }
      if (action.payload.status === CentricItemStatus.LOADING) {
        state.cnsPrice = {
          ...state.cnsPrice,
          status: action.payload.status,
        }
      }
      if (action.payload.status === CentricItemStatus.ERROR) {
        state.cnsPrice = {
          ...state.cnsPrice,
          expiry: moment().add(9, 's').valueOf(),
          status: action.payload.status,
        }
      }
    },
    updateInfo: (state, action: PayloadAction<CentricInfo>) => {
      if (action.payload.status === CentricItemStatus.FINISHED) {
        state.info = {
          data: action.payload.data,
          status: action.payload.status,
          isInitialized: true,
          expiry: moment().add(5, 'm').valueOf(),
        }
      }
      if (action.payload.status === CentricItemStatus.LOADING) {
        state.info = {
          ...state.info,
          status: action.payload.status,
        }
      }
      if (action.payload.status === CentricItemStatus.ERROR) {
        state.info = {
          ...state.info,
          expiry: moment().add(9, 's').valueOf(),
          status: action.payload.status,
        }
      }
    },
  },
})

// Actions
export const { updateCnrPrice, updateCnsPrice, updateInfo } = centricSlice.actions

export default centricSlice.reducer
