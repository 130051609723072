import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'

export const formatDecimals = (value: string, decimals: number) => ethers.utils.formatUnits(value, decimals)

export const formatLocal = (value: string, decimals: number) =>
  Number(value).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })

export const BIG_ZERO = new BigNumber(0)
export const divide = (n, d) => new BigNumber(n).dividedBy(d).toString()
export const divideToFixed = (n, d) => new BigNumber(n).dividedBy(d).toFixed(0)
export const divideFixed = (n, d, length) => new BigNumber(n).dividedBy(d).toFixed(length)
export const multiply = (n, d) => new BigNumber(n).times(d).toString()
export const minus = (n, d) => new BigNumber(n).minus(d).toString()
export const minusFixed = (n, d, length) => new BigNumber(n).minus(d).toFixed(length)
export const add = (n, d) => new BigNumber(n).plus(d).toString()
export const isLessOrEqual = (n, d) => new BigNumber(n).isLessThanOrEqualTo(d)
export const isGreater = (n, d) => new BigNumber(n).isGreaterThan(d)
export const isGreaterOrEqual = (n, d) => new BigNumber(n).isGreaterThanOrEqualTo(d)
export const multiplyFixed = (n, d, length) => new BigNumber(n).times(d).toFixed(length)

export const formatAbbr = (n) => {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}k`
  if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`
  if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`
  if (n >= 1e12) return `${+(n / 1e12).toFixed(1)}T`
  return n
}
