import React from 'react'
import { NavLink } from 'react-router-dom'
import { Layout, Menu, Grid, Popover } from 'antd'
import { MenuFoldOutlined } from '@ant-design/icons'
import ShowIcon from 'components/Images/icons/Icons'
import NavMenu from './NavMenu'
import { LeftContainer, RightContainer } from './styled-components'

const { Header } = Layout
const { useBreakpoint } = Grid

const AppHeader: React.FC = () => {
  const screens = useBreakpoint()

  return (
    <Header
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 0 0 16px',
        backgroundColor: '#000',
      }}
    >
      <LeftContainer>
        <div style={{ marginRight: 30 }}>
          <NavLink to="/" style={{ display: 'flex' }}>
            <ShowIcon icon="logo_explorer" />
          </NavLink>
        </div>
        {screens.md ? (
          <NavMenu type="desktop" />
        ) : (
          <Popover
            content={<NavMenu type="mobile" />}
            trigger="click"
            placement="bottomRight"
            overlayStyle={{ padding: 0 }}
            overlayClassName="AppHeader-mobile-popover"
          >
            <MenuFoldOutlined style={{ color: '#fff', fontSize: '1.5rem' }} />
          </Popover>
        )}
      </LeftContainer>
      {screens.md ? (
        <RightContainer>
          <Menu theme="dark" mode="horizontal" style={{ backgroundColor: '#000' }}>
            <Menu.Item key="wallet">
              <a href="https://tron-explorer.centric.com" target="_blank" rel="noopener noreferrer">
                Tron Explorer
              </a>
            </Menu.Item>
            <Menu.Item key="joincentric">
              <a href="https://www.centric.com" target="_blank" rel="noopener noreferrer">
                About Centric
              </a>
            </Menu.Item>
          </Menu>
        </RightContainer>
      ) : null}
    </Header>
  )
}

export default AppHeader
