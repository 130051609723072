import RISE_ABI from './abi/rise.json'
import SWAP_ABI from './abi/swap.json'

export const BSC_API = 'https://api.bscscan.com'
export const BSC_API_KEY = process.env.REACT_APP_BSC_API_KEY
export const BSC_SCAN = 'https://bscscan.com'
export const BSC_RCP_ENDPOINTS =
  'https://bsc-dataseed.binance.org/,https://bsc-dataseed1.defibit.io/,https://bsc-dataseed1.ninicoin.io/'
export const CHAIN_ID = '56'
export const CENTRIC_API = 'https://wallet-api.centric.com/api'
export const GECKO_API = 'https://api.coingecko.com/api'
export const BURN_ADDRESS = '0x0000000000000000000000000000000000000000'
export enum ChainId {
  MAINNET = 56,
  TESTNET = 97,
}

export { RISE_ABI, SWAP_ABI }
