import styled from 'styled-components'

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-right: 16px;
  @media (min-width: 768px) {
    width: auto;
    justify-content: flex-start;
    padding-right: 0;
  }
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    background-color: #000;
  }
`

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    background-color: #000;
  }
`
