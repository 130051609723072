import React from 'react'
import { Result } from 'antd'

const NotFound: React.FC = () => {
  return (
    <Result
      style={{ marginTop: '4rem' }}
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  )
}

export default NotFound
