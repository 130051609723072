import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import ApplicationUpdater from 'state/application/updater'
import { ScrollToTop } from 'components'
import App from './App'
import Providers from './Providers'
import history from './routerHistory'

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
    </>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Router history={history}>
        <Updaters />
        <ScrollToTop />
        <App />
      </Router>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)
